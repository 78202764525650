import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import placeholderImage from '../../assets/images/placeholders/featured-media-placeholder.svg';
import * as styles from './FeaturedMediaDefault.module.scss';

const FeaturedMediaDefault = (props) => {
  const { componentStyle } = props;
  const containerClasses = [styles.featuredMediaImage];

  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  switch (componentStyle) {
    case 'home':
      containerClasses.push(styles.homeStyle);
      break;
    case 'artArchive':
      containerClasses.push(styles.artArchiveStyle);
      break;
    default:
      containerClasses.push(styles.defaultStyle);
  }

  return (
    <div className={containerClasses.join(' ')}>
      <img className={styles.image} src={placeholderImage} alt={title} />
    </div>
  );
};

FeaturedMediaDefault.propTypes = {
    componentStyle: PropTypes.string.isRequired,
};

export default FeaturedMediaDefault;
