import { Link, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './ArtPostsListing.module.scss';
const ArtPostsListing = (props) => {
  const placeholder = useStaticQuery(graphql`
    query ArtPostPlaceholderQuery {
      artPostPlaceholder: file(relativePath: { eq: "placeholders/art-post-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
        }
      }
    }
  `);
  const { posts } = props;

  const noContent = () => {
    return <h2>No Posts Found.</h2>;
  };

  const postImage = (post) => {
    const postImage = post?.featuredImage?.node?.localFile
      ? post.featuredImage.node.localFile
      : placeholder;
    return <GatsbyImage image={getImage(postImage)} alt={post.title} />;
  };

  const postContent = () => {
    if (!posts.length) return noContent();

    return (
      <>
        {posts.map((post) => {
          return (
            <div key={post.id} className={styles.artPostGridItem}>
              <div className={styles.postMetaWrap}>
                <div className={[styles.postDetails, 'js-reveal-container'].join(' ')}>
                  <div className="js-reveal">
                    {/* <p className={styles.postDate}>{post.date}</p> */}
                    {post?.artPostContent?.subtitle && (
                      <p className={styles.postSubtitle}>{post.artPostContent.subtitle}</p>
                    )}
                    <h2 className={styles.postTitle}>{post.title}</h2>
                  </div>
                </div>
                <div className={[styles.postThumbnail, 'js-reveal-container'].join(' ')}>
                  <div className="js-reveal-image">{postImage(post)}</div>
                </div>
              </div>
              <div className="js-reveal-container">
                <div className="js-reveal">
                  {post?.artPostContent?.postDescription && (
                    <div
                      className={styles.postDescription}
                      dangerouslySetInnerHTML={{ __html: post.artPostContent.postDescription }}
                    ></div>
                  )}
                </div>
              </div>
              <div className="js-reveal-container">
                <div className="js-reveal">
                  <div className={styles.postReadMore}>
                    <Link className="button hollow" to={post.uri}>View the series</Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="grid-container fluid">
      <div className="grid-x grid-margin-x align-center">
        <div className="cell small-12 medium-8 large-6">{postContent()}</div>
      </div>
    </div>
  );
};

export default ArtPostsListing;
