import React from 'react';
import PropTypes from 'prop-types';
import FeaturedMedia from '../FeaturedMedia/FeaturedMedia';
import ScrollDownButton from '../ScrollDownButton/ScrollDownButton';

const ArtArchiveHead = (props) => {
  return (
    <div className="hero">
      <FeaturedMedia componentStyle="artArchive" featuredMedia={props.featuredMedia} />
      <ScrollDownButton className="scroll-down-arrow-wrap" />
    </div>
  );
};

ArtArchiveHead.propTypes = {
  featuredMedia: PropTypes.shape({
    enableParallax: PropTypes.bool,
    featuredMedia: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};

export default ArtArchiveHead;
