import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import * as styles from './FeaturedMedia.module.scss';

import FeaturedMediaImage from './FeaturedMediaImage';
import FeaturedMediaVideo from './FeaturedMediaVideo';
import FeaturedMediaDefault from './FeaturedMediaDefault';

import useParallax from '../../animations/useParallax';

const FeaturedMedia = (props) => {
    const {featuredMedia: {
        enableParallax,
        featuredMedia,
    }, componentStyle} = props;

    const parallaxParent = useRef(null);
    const parallaxChild = useRef(null);

    useParallax({
        parent: parallaxParent,
        child: parallaxChild,
        enableParallax: enableParallax
    });
    
    const renderMedia = (media, index) => {
        switch (media.__typename) {
            case "WpPage_Featuredmedia_FeaturedMedia_FeaturedImage":
            case "WpPost_Featuredmedia_FeaturedMedia_FeaturedImage":
              const { image } = media;
              if (image) {
                return (
                  <FeaturedMediaImage
                    image={image}
                    componentStyle={componentStyle}
                    key={index}
                  />
                );
              } else {
                return <FeaturedMediaDefault componentStyle={componentStyle} key={index}/>;
              }
            case "WpPage_Featuredmedia_FeaturedMedia_FeaturedVideo":
            case "WpPost_Featuredmedia_FeaturedMedia_FeaturedVideo":
              const { mobileVideoUrl, desktopVideoUrl } = media;
      
              if (mobileVideoUrl && desktopVideoUrl) {
                return (
                  <FeaturedMediaVideo
                    mobileVideoUrl={mobileVideoUrl}
                    desktopVideoUrl={desktopVideoUrl}
                    componentStyle={componentStyle}
                    key={index}
                  />
                );
              } else {
                return <FeaturedMediaDefault componentStyle={componentStyle} key={index}/>;
              }
            default:
              return null;
          }
    }

    return (
        <div ref={parallaxParent} className={styles.featuredMediaContainer}>
            <div ref={parallaxChild}>
                {featuredMedia.map(renderMedia)}
            </div>
        </div>
    );
}



// componentStyle - ['default', 'home', 'artArchive' ]
FeaturedMedia.defaultProps = {
    componentStyle: 'default',
}
FeaturedMedia.propTypes = {
    featuredMedia: PropTypes.shape({
      enableParallax: PropTypes.bool.isRequired,
      featuredMedia: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    componentStyle: PropTypes.string
}


export default FeaturedMedia;