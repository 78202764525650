import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import * as styles from './FeaturedMediaVideo.module.scss';
import { canUseDOM } from '../../utils/utils';
const FeaturedMediaVideo = (props) => {
    const {mobileVideoUrl, desktopVideoUrl, componentStyle} = props;
    const [videoUrl, setVideoUrl] = useState(null);

    const windowResized = () => {
        if( canUseDOM && window?.innerWidth < 640){
            setVideoUrl(mobileVideoUrl)
        }else {
            setVideoUrl(desktopVideoUrl)
        }
    }

    useEffect(() => {
        windowResized();
        if( canUseDOM ){
            window.addEventListener('resize', windowResized);
            return( () => {
                window.removeEventListener('resize', windowResized);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const containerClasses = [styles.featuredMediaVideo];
    switch(componentStyle){
        case 'home':
            containerClasses.push(styles.homeStyle);
            break;
        case 'artArchive':
            containerClasses.push(styles.artArchiveStyle);
            break;
        default:
            containerClasses.push(styles.defaultStyle);
    }

    return (
        <div className={containerClasses.join(' ')}>
            <video src={videoUrl} className={styles.video} playsInline={true} autoPlay loop muted />
        </div>
    )
}

FeaturedMediaVideo.propTypes = {
    mobileVideoUrl: PropTypes.string.isRequired,
    desktopVideoUrl: PropTypes.string.isRequired,
    componentStyle: PropTypes.string.isRequired
}
export default FeaturedMediaVideo;