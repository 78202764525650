import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ArtArchiveHead from '../components/ArtArchive/ArtArchiveHead';

import PageDescription from '../components/ArtArchive/PageDescription';
import ArtPostsListing from '../components/ArtArchive/ArtPostsListing';
import YoastSeo from '../components/YoastSeo';
const artArchive = (props) => {
  const {
    data: {
      artArchivePage: {
        title: pageTitle,
        featuredMedia,
        uri,
        seo,
        template: { artArchiveContent },
      },
      artPosts,
    },
    pageContext,
  } = props;

  return (
    <Layout bodyClass={`page-template-art-archive`} pageContext={pageContext}>
      <YoastSeo
          lang={pageContext.languageCode.toLowerCase()}
          {...seo}
          uri={uri}
        />
      {featuredMedia?.featuredMedia && <ArtArchiveHead featuredMedia={featuredMedia} />}

      {artArchiveContent?.content && <PageDescription title={pageTitle} content={artArchiveContent.content} />}

      <ArtPostsListing posts={artPosts.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query ($languageCode: WpLanguageCodeEnum, $pageId: String) {
    artArchivePage: wpPage(id: { eq: $pageId }, language: { code: { eq: $languageCode } }) {
      id
      title
      uri
      seo {
        ...YoastSEOContent
      }
      template {
        ... on WpTemplate_ArtArchive {
          templateName
          artArchiveContent {
            content
          }
        }
      }
      featuredMedia {
        ...FeaturedMediaContent
      }
    }
    artPosts: allWpArt(filter: { language: { code: { eq: $languageCode } } }) {
      nodes {
        id
        uri
        language {
          code
        }
        title
        date(formatString: "DD/MM/YYYY")
        artPostContent {
          subtitle
          postDescription
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default artArchive;
