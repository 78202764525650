import React from 'react';
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from './FeaturedMediaImage.module.scss';
const FeaturedMediaImage = (props) => {
    const {componentStyle, image} = props;
    const img = getImage(image.localFile);

    const containerClasses = [styles.featuredMediaImage];

    switch(componentStyle){
        case 'home':
            containerClasses.push(styles.homeStyle);
            break;
        case 'artArchive':
            containerClasses.push(styles.artArchiveStyle);
            break;
        default:
            containerClasses.push(styles.defaultStyle);
    }

    return(
        <div className={containerClasses.join(' ')}>
            <GatsbyImage className={styles.image} image={img} alt={image.altText} />
        </div>
    )
}

FeaturedMediaImage.propTypes = {
    image: PropTypes.object.isRequired,
    componentStyle: PropTypes.string.isRequired
}
export default FeaturedMediaImage;