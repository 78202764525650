import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './PageDescription.module.scss';
const PageDescription = (props) => {
  const { title, content } = props;
  return (
    <div className={[styles.descriptionWrap, 'grid-container fluid'].join(' ')}>
      <div className="grid-x grid-margin-x align-center">
        <div className="cell medium-8 large-6 js-reveal-container">
          <h2 className={[styles.title, 'js-reveal'].join(' ')}>{title}</h2>
          {content && (
            <div
              className={[styles.description, 'js-reveal'].join(' ')}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
      </div>
      <div className="js-reveal-container">
        <div className="js-reveal">
          <hr />
        </div>
      </div>
    </div>
  );
};

PageDescription.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};
export default PageDescription;
